.SuccessAlert .AlertOverlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.AlertOverlay {
  background: rgba(49, 49, 49, 0.8);
}
.succesIcon {
  color: green;
}
.SuccesAlertContent {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 14px 20px;
  width: 220px;
  border: 1px solid green;
  font-weight: bold;
}
.SuccesAlertContent h6 {
  max-width: fit-content;
}

/* modal for  transfer ownership button */
body.active-modal {
  overflow-y: hidden;
}
.modall,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  border: 4px solid #612881;
}

.account_banner_section {
  background-image: url(../../images/inner_page_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 350px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.account_banner_section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  pointer-events: none;
  opacity: 0.8;
}
.account_banner_section > .container {
  position: relative;
}
.acc_inner_bg_content h1 {
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 60px;
  font-family: "Passion One", cursive;
  line-height: 1;
  margin-bottom: 10px;
  color: #fff;
}
.acc_inner_bg_content p {
  font-weight: 600;
  font-size: 28px;
  color: #d6d6d6;
}
.HomeBtn {
  padding: 15px;
  border: 1px #612881;
  border-radius: 10px;
  background-color: #612881;
  color: #fff;
  margin-top: 20px;
}
.HomeBtn:hover {
  background-color: #fff;
  color: #612881;
}
/* My Account Section Start*/
.my_account_section {
  padding: 120px 0 55px;
}
.my_account_tab_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 84px rgba(125, 125, 125, 0.1);
  box-shadow: 0px 4px 84px rgba(125, 125, 125, 0.1);
}
.tab_section {
  width: 273px;
  border-right: 1px solid #f4f4f4;
}
.tab_content_block {
  width: calc(100% - 273px);
}
.tab_content {
  display: none;
}
.tab_content.active {
  display: block;
}
.tab_link_title {
  min-height: 104px;
  background: #fdfaff;
  padding: 38px 45px;
}
.tab_link_title h3 {
  font-weight: bold;
  font-size: 22px;
  color: #612881;
}
.tab_block button {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-weight: 500;
  font-size: 20px;
  color: #adadad;
  padding: 19px 15px 19px 45px;
  border-bottom: 1px solid #f4f4f4;
  text-align: left;
}
.tab_block .hidden {
  display: none;
}
.tab_block button span {
  min-width: 35px;
  display: inline-block;
  pointer-events: none;
}
.tab_block button img.tab_img2,
.tab_block button.active img.tab_img1 {
  display: none;
}
.tab_block button.active img.tab_img2 {
  display: inline-block;
}

.tab_block button.active {
  color: #0b0b0b;
}

.tab_block button img {
  display: inline-block;
  vertical-align: middle;
}

.common_tab_content_register {
  min-height: 104px;
  background: #fdfaff;
  padding: 26px 42px;
  color: red;
}
.common_tab_content_title {
  min-height: 104px;
  background: #fdfaff;
  padding: 26px 42px;
}
.common_tab_content_title h3 {
  font-weight: bold;
  font-size: 42px;
  color: #612881;
}
.cm_tab_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -7px;
}
.cm_tab_hf_col {
  padding: 0 7px;
  width: 50%;
}
.cm_tab_f_col {
  width: 100%;
  padding: 0 7px;
}
.common_tab_form_block {
  padding: 30px 42px;
}
.tab_block {
  padding-top: 10px;
}
.cm_input_hold label {
  font-weight: 500;
  font-size: 20px;
  color: #adadad;
  margin-bottom: 7px;
}
.cm_input_hold input {
  width: 100%;
  height: 60px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 20px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #eeeeee;
}
.cm_input_hold {
  margin-bottom: 20px;
}
.cm_input_hold input:focus {
  border-color: #9d6ab9;
  outline: 0;
}
.cm_input_hold .common_checkbox label {
  margin-bottom: 0;
  font-size: 18px;
}
.cm_input_hold .common_checkbox input:checked + label {
  color: #000;
}
.upload_label h3 {
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.upload_image_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.upload_label {
  width: 300px;
}
.upload_image_hold {
  width: calc(100% - 300px);
}
.img_upload_box {
  background: #ededed;
  padding: 10px 40px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.img_upload_box input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.img_upload_box img {
  max-width: 50px;
}
.cm_update_btn {
  margin: 45px 0 25px;
}
.cm_update_btn .common_btn {
  width: 100%;
}
.image_show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}
.imgThumbContainer {
  width: 219px;
  height: 142px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 10px;
}
.IMGthumbnail {
  height: 100%;
}
.imgThumbContainer img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  height: 100%;
}
span.delete {
  position: absolute;
  z-index: 1;
  background: #612881;
  right: -6px;
  top: -6px;
  color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  height: 25px;
  line-height: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px 0 3px 2px;
  cursor: pointer;
}
/* My Account Section End*/
/* Registered Vehicles Start */
.registered_vehicles_section {
  padding-bottom: 120px;
}
.registered_vehicles_box {
  padding: 70px 60px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.05);
}
.registered_vehicles_box .common_section_title h2 {
  color: #612881;
}
.registered_v_inner_box {
  padding: 40px 95px 40px 50px;
  background: #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  margin-top: 25px;
}
.rv_col_6 {
  width: 50%;
}
.registered_v_inner_content h3 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}
.registered_v_inner_content .returned_v_info {
  max-width: 100%;
  margin: 0;
}
.registered_v_inner_content .returned_v_info ul {
  margin: 0;
}
.registered_v_inner_content .returned_v_info ul li {
  width: 100%;
  padding: 0;
  margin: 0;
}
.registered_v_inner_content .returned_v_info ul li + li {
  margin-top: 10px;
}
.registered_v_inner_box .rv_edit_btn {
  top: 40px;
  right: 30px;
}
/* Registered Vehicles End */
/* Google Pay Seation Start */
.selectProductsAndPackages {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 0;
}
.selectProductsAndPackages input {
  padding: 10px;
}
.selectProductsAndPackages label {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding: 5px;
}
.ProductsInput {
  margin-left: 50px;
}

.google_pay_section {
  padding: 65px 0 120px;
}
.google_pay_box {
  padding: 60px 60px 50px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 84px rgba(125, 125, 125, 0.1);
  box-shadow: 0px 4px 84px rgba(125, 125, 125, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.go_col_left {
  width: 385px;
}
.go_col_right {
  width: calc(100% - 385px);
  padding-left: 30px;
}
.go_vehicle_desc h3 {
  font-weight: bold;
  font-size: 24px;
}
.go_vehicle_desc {
  margin-top: 20px;
}
.go_vehicle_desc h3 span {
  color: #612881;
}
.go_vehicle_desc h3 + h3 {
  margin-top: 20px;
}
.go_card_label {
  background-color: #000;
  padding: 18px 15px 13px;
  text-align: center;
}
.go_pay_card_title {
  position: relative;
  text-align: center;
  margin: 30px 0 20px;
}
.go_pay_card_title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #adadad;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}
.go_pay_card_title h4 {
  font-weight: bold;
  font-size: 20px;
  color: #adadad;
  padding: 0px 20px;
  background-color: #fff;
  display: inline-block;
  position: relative;
}
.go_play_form .cm_input_hold label {
  color: #612881;
  font-weight: bold;
}
.go_play_form .cm_input_hold input::-webkit-input-placeholder {
  color: #adadad;
}
.cm_input_hold.gp_card_info {
  margin-bottom: 0;
  position: relative;
}
.gp_card_info input {
  padding-right: 230px;
}
.gp_card_img {
  position: absolute;
  top: 56px;
  right: 15px;
}
.gp_date_cvc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gp_date_cvc input {
  width: 50%;
}
.gy_payment_btn .common_btn {
  width: 100%;
  margin-top: 10px;
}
#pakage_selector:focus-visible,
.size_select:focus-visible {
  outline: none;
}
.go_vehicle_desc h3 span.size_selector.active {
  display: block;
}
.go_vehicle_desc h3 span.size_selector {
  display: none;
}
#pakage_selector,
.size_select {
  border: none;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  padding-right: 25px;
  padding-left: 5px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 3px top 10px;
  background-image: url(../../images/dropdown.png);
  font-size: 24px;
  font-weight: 600;
}
/* Product and packages  section */
.HideSection {
  display: none;
}
/* Google Pay Seation End */
/* My Deails Tab Start*/
.cm_form_inner_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}
.cm_form_inner_title h3 {
  font-weight: 500;
  font-size: 30px;
}
.cm_form_inner_title h3 img {
  filter: brightness(0) invert(0);
  -webkit-filter: brightness(0) invert(0);
  display: inline-block;
  min-width: 25px;
  margin-right: 13px;
  margin-top: -7px;
}
.cm_form_inner_title p a {
  font-weight: 500;
  font-size: 20px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #612881;
  display: inline-block;
}
.cm_form_inner_title p a:hover {
  color: #000;
}
.my_details_form .cm_update_btn {
  margin: 20px 0 25px;
}
/* My Deails Tab End*/
/* Overview Tab Start*/
.overview_form_block .cm_update_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 45px 7px 25px;
}
.cm_up_col {
  padding: 0 7px;
  width: 50%;
}
.br_common_btn {
  background-color: transparent;
  color: #612881;
}
.br_common_btn:hover {
  background-color: #612881;
  color: #fff;
}
.overview_form_block .cm_form_inner_title h3 {
  font-size: 24px;
}
.overview_form_block .cm_form_inner_title h3 img {
  min-width: 18px;
  margin-right: 8px;
  margin-top: -4px;
}
/* Overview Tab End*/
.react-date-picker {
  width: 100%;
}
.react-date-picker__clear-button,
.react-date-picker__calendar-button {
  display: none;
}
.react-date-picker__wrapper {
  border: 1px solid #eeeeee !important;
  padding-left: 15px;
}
.react-date-picker input {
  border: 0;
  padding: 0 5px;
  height: 58px;
}
.hilight {
  color: #0b0b0b;
}

/*=============== Media CSS Start=================*/
@media (max-width: 1199px) {
  .registered_vehicles_box {
    padding: 40px;
  }
  .registered_v_inner_box {
    padding: 30px 95px 30px 30px;
  }
  .registered_v_inner_content h3 {
    font-size: 20px;
  }
  .registered_vehicles_box .returned_v_info ul li p {
    font-size: 16px;
  }
  .registered_v_inner_box .rv_edit_btn {
    top: 30px;
    right: 20px;
  }
  .common_tab_content_title h3 {
    font-size: 32px;
  }
  .common_tab_content_title {
    padding: 32px 42px;
  }
  .tab_section,
  .tab_content_block {
    width: 100%;
  }
  #reg_label {
    text-align: left;
    padding-left: 46px;
  }
  #reg_label br {
    display: none;
  }
  .hidden {
    display: none;
  }
}
@media (max-width: 991px) {
  .acc_inner_bg_content h1 {
    font-size: 50px;
  }
  .google_pay_box {
    padding: 40px;
  }
  .go_col_left {
    width: 100%;
    margin-bottom: 30px;
  }
  .go_col_right {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .my_account_section {
    padding: 50px 0;
  }
  .common_tab_content_title h3 {
    font-size: 25px;
  }
  .common_tab_content_title {
    padding: 25px 25px;
    min-height: 80px;
  }
  .common_tab_form_block {
    padding: 30px 25px;
  }
  .tab_block button {
    padding: 19px 15px 19px 25px;
  }
  .cm_input_hold label {
    font-size: 16px;
  }
  .tab_link_title {
    min-height: 80px;
    padding: 25px 25px;
  }
  .cm_input_hold input {
    height: 50px;
    padding: 10px;
    font-size: 16px;
  }
  .react-date-picker input {
    height: 48px;
    padding: 0 0px;
  }
  .react-date-picker__wrapper {
    padding-left: 10px;
  }

  .rv_col_6 {
    width: 100%;
  }
  .registered_v_img {
    margin-top: 25px;
  }
  .registered_v_inner_box {
    padding: 25px;
    margin-top: 15px;
  }
  .registered_v_inner_box .rv_edit_btn {
    top: 15px;
    right: 15px;
  }
  .registered_vehicles_box,
  .google_pay_box {
    padding: 25px;
  }
  .registered_vehicles_section {
    padding-bottom: 50px;
  }
  .registered_v_inner_content h3 {
    max-width: 90%;
  }
  .google_pay_section {
    padding: 0px 0 50px;
  }
  .gp_card_img {
    top: 50px;
    max-width: 120px;
  }
  /* .go_vehicle_desc h3, */
  #pakage_selector,
  .size_select {
    font-size: 20px;
  }
  #pakage_selector,
  .size_select {
    padding-right: 17px;
    background-size: 10px;
    background-position: right 3px top 10px;
  }
  .go_pay_card_title h4 {
    font-size: 18px;
  }
  .go_card_label {
    padding: 15px 15px 10px;
  }
  .cm_form_inner_title h3 {
    font-size: 20px;
  }
  .cm_form_inner_title h3 img {
    min-width: 15px;
    margin-right: 10px;
    margin-top: -3px;
  }
  .cm_form_inner_title p a {
    font-size: 16px;
  }
  .upload_label {
    width: 100%;
  }
  .upload_image_hold {
    width: 100%;
  }
  #reg_label {
    padding-left: 26px;
  }
}

@media only screen and (max-width: 450px) {
  .SuccesAlertContent {
    position: absolute;
    top: 180%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px 20px;
    width: 220px;
    border: 1px solid green;
    font-weight: bold;
  }
  .SaveProfileAlert {
    top: 150%;
  }
  .RegistreVehicleAlert {
    top: 150%;
  }
  .TransferBikeAlert {
    top: 200%;
  }
  /* .PasswordChangedAlert {
    position: none;
    top: 80% !important;
  } */
  .selectProductsAndPackages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ProductsRadio {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 68px;
  }
  .ProductsRadio label {
    padding-left: px;
    margin-left: 10px;
  }
  .ProductsRadio input {
    margin-left: 60px;
  }
  go_vehicle_desc option {
    background-color: white;
  }
}
@media (max-width: 575px) {
  .tab_block button {
    font-size: 18px;
  }
  .tab_block {
    padding-top: 0px;
  }
  .acc_inner_bg_content h1 {
    font-size: 40px;
    margin-bottom: 0;
  }
  .acc_inner_bg_content p {
    font-size: 23px;
  }
  .account_banner_section {
    min-height: 280px;
  }
  .upload_label h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .cm_input_hold .common_checkbox label {
    font-size: 14px;
  }
  .cm_input_hold .stolen_check {
    margin-top: 15px;
  }
  .gp_date_cvc input {
    width: 100%;
  }
  .common_checkbox input:checked + label:after {
    top: 1px;
  }
  .cm_up_col {
    width: 100%;
  }
  .cm_up_col + .cm_up_col {
    margin-top: 10px;
  }
}
@media (max-width: 450px) {
  .cm_tab_hf_col {
    width: 100%;
  }
  .cm_input_hold {
    margin-bottom: 10px;
  }
  .gp_card_info input {
    padding-top: 40px;
    padding-bottom: 10px;
    height: 75px;
  }
  .gp_card_img {
    right: unset;
    max-width: 140px;
    left: 10px;
    top: 45px;
  }
  .go_pay_card_title h4 {
    font-size: 16px;
    padding: 0 10px;
  }
  .go_vehicle_desc h3 span {
    display: inline-block;
  }
  .go_vehicle_desc h3 span.size_selector {
    display: none;
  }
  .cm_form_inner_title h3 {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 375px) {
  .registered_vehicles_box .returned_v_info ul li:first-child p {
    max-width: 150px;
  }
}
@media (max-width: 367px) {
  .upload_label h3 {
    font-size: 15px;
  }
  .registered_v_inner_content h3 {
    font-size: 18px;
  }
  .registered_v_inner_box {
    padding: 15px;
  }
}
@media only screen and (max-width: 380px) {
  .SuccesAlertContent {
    position: absolute;
    top: 250%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px 20px;
    width: 220px;
    border: 1px solid green;
    font-weight: bold;
  }
  .SaveProfileAlert {
    top: 200%;
  }
  .RegistreVehicleAlert {
    top: 200%;
  }
  transferBikeAlert {
    top: 350%;
  }
}
