/*
  font-family: 'Poppins', sans-serif;
  font-family: 'Passion One', cursive;
*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  color: #000;
  background: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}
a,
span,
a:hover,
a:active,
button {
  text-decoration: none;
}
a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus {
  outline-width: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}
p {
  margin: 0;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  max-width:90%;
}
.cm-overflow {
  height: 100%;
}
.cm-overflow,
.cm-overflow body {
  overflow: hidden;
  -ms-touch-action: unset;
  touch-action: unset;
}
.row {
  margin: 0 -15px;
  justify-content: center;
}
.container {
  padding: 0 15px;
}
.col,
[class*='col-'] {
  padding: 0 15px;
}
#error_page {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
/* Header Section Start */
header {
  width: 100%;
  z-index: 100;
  padding: 11px 0;
  background-color: #fff;
}
.header_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo a {
  color: #146;
  font-weight: 700;
  font-size: 30px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.logo a:hover {
  color: black;
}
.menus ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  padding-left: 54px;
}
.menus ul li {
  display: inline-block;
  margin-right: 30px;
}
.menus ul li:last-child {
  margin-right: 0;
}
.menus ul li a {
  font-size: 20px;
  color: #000;
  font-weight: 400;
  display: inline-block;
}
.menus ul li a.active,
.menus ul li a:hover {
  color: #612881;
}
.menus ul li a.active {
  font-weight: 700;
}
.logo {
  min-width: 404px;
}
.menu_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.common_rl_btn {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  background: #612881;
  padding: 17px 15px 13px;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  font-family: 'Passion One', cursive;
  font-weight: 400;
  line-height: 1;
  min-width: 175px;
  border: 1px solid #612881;
}
.common_rl_btn:hover {
  background-color: transparent;
  color: #612881;
}
.social_icon_login_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}
.login_rigster_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 35px;
}
.login_rigster_btn a + a {
  margin-left: 10px;
}
.register_btn {
  background-color: transparent;
  color: #612881;
}
.register_btn:hover {
  color: #fff;
  background-color: #612881;
}
.header_s_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_s_icon a + a {
  margin-left: 30px;
}
.header_s_icon a {
  display: inline-block;
}
.header_s_icon a:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.header_s_icon a.login_after,
.menu_toggle_btn {
  display: none;
}
.menu_toggle_btn span {
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  background-color: #000;
  border-radius: 35px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.menu_toggle_btn span:last-child {
  margin-bottom: 0;
}
.menu_toggle_btn span:nth-child(1) {
  margin-top: 5px;
}
.open_menu .menu_toggle_btn span:nth-child(1) {
  -webkit-transform: rotate(45deg) translateY(2px) translateX(5px);
  -ms-transform: rotate(45deg) translateY(2px) translateX(5px);
  transform: rotate(45deg) translateY(2px) translateX(5px);
}
.open_menu .menu_toggle_btn span:nth-child(2) {
  opacity: 0;
}
.open_menu .menu_toggle_btn span:nth-child(3) {
  -webkit-transform: rotate(-45deg) translateY(-7px) translateX(9px);
  -ms-transform: rotate(-45deg) translateY(-7px) translateX(9px);
  transform: rotate(-45deg) translateY(-7px) translateX(9px);
}
.mobile_menus {
  display: none;
}
.humberger_and_icons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* Header Section End */
/* Home Banner Section Start */
.home_banner_section {
  width: 100%;
  min-height: 838px;
  position: relative;
  background: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.home_banner_section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../src/images/jackMB.jpeg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  opacity: 0.2;
  pointer-events: none;
}
.home_banner_content {
  max-width: 835px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}
.home_label_text p {
  display: inline-block;
  position: relative;
  font-size: 24px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
  color: #612881;
  padding: 0 15px;
}
.home_label_text p::before,
.home_label_text p::after {
  content: '';
  position: absolute;
  width: 66px;
  height: 3px;
  background: #612881;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home_label_text p::before {
  left: 100%;
}
.home_label_text p::after {
  right: 100%;
}
.home_banner_title h1 {
  font-size: 60px;
  color: #fff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-family: 'Passion One', cursive;
  font-weight: 400;
  line-height: 1;
}
.home_banner_content .common_desc {
  margin: 20px auto 40px;
  max-width: 800px;
}
.home_banner_content .common_desc p {
  color: #fff;
}
.home_banner_search_bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.home_banner_search_bar button {
  width: 225px;
  min-height: 64px;
  background: #612881;
  color: #fff;
  border: 0;
  font-size: 20px;
  font-weight: 600;
}
.home_banner_search_bar button:hover {
  background-color: #4e1070;
}
.home_banner_search_bar input {
  width: calc(100% - 225px);
  min-height: 64px;
  padding: 15px 30px;
  background-color: #fff;
  border: 1px solid #dddddd;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  font-size: 18px;
  color: #000;
}
.home_banner_search_bar #error_invalid {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
#error_invalid a {
  color: red;
  text-decoration: underline;
}
#error_invalid {
  display: none;
}
.home_banner_search_bar input::-webkit-input-placeholder {
  color: #000;
}
.home_banner_search_bar input:focus {
  outline: 0;
}
/* Home Banner Section End */
/* Returned Vehicle Start */
.returned_vehicle_section {
  margin-top: 50px;
  display: none;
}
.returned_vehicle_box {
  background: #f5f5f5;
  padding: 50px 80px;
  position: relative;
  border: 2px solid rgb(191, 191, 191);
}
.common_section_title h2 {
  color: #000000;
  font-size: 54px;
  line-height: 1;
  font-family: 'Passion One', cursive;
  font-weight: 400;
}
.returned_vehicle_box .common_section_title h2 {
  color: #612881;
}
.returned_v_info {
  margin: 20px 0 0px;
  max-width: 930px;
}
.returned_v_info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.returned_v_info ul li {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;
}
.returned_v_info ul li p b {
  font-weight: 600;
}
.returned_v_info ul li p {
  font-size: 24px;
  color: #adadad;
}
.stolen_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.stolen_checkbox p {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  margin-right: 15px;
}
.st_check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.st_check .common_checkbox input:checked + label {
  color: #000;
}
.common_checkbox label {
  position: relative;
  cursor: pointer;
  color: rgb(75 54 56 / 40%);
  letter-spacing: 0.03em;
}
.common_checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}
.common_checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: 1px solid #612881;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.common_checkbox input:checked + label:before {
  border: 1px solid #612881;
}
.common_checkbox input {
  display: none;
}
.st_check .common_checkbox + .common_checkbox {
  margin-left: 15px;
}
.common_btn {
  display: inline-block;
  padding: 16px 15px 14px;
  background: #612881;
  color: #fff;
  border: 2px solid #612881;
  font-size: 22px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 1;
}
.my_bikes_spacer {
  display: block;
  margin-left: 70px;
  margin-top: 30px;
  width: 40%;
}

.common_btn:hover {
  background-color: transparent;
  color: #612881;
}
.common_btn:disabled {
  background-color: #dddddd !important;
  color: #ffffff !important;
}
.common_btn:hover:disabled {
  background-color: #dddddd !important;
  color: #ffffff !important;
}
.returned_vehicle_box .common_btn {
  margin-top: 30px;
  max-width: 278px;
  width: 100%;
}
.rv_edit_btn {
  position: absolute;
  top: 55px;
  right: 80px;
}
.rv_edit_btn a {
  width: 30px;
  height: 30px;
  border: 1px solid #612881;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.rv_edit_btn a:hover {
  background-color: #612881;
}
.rv_edit_btn a img {
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 20px;
}
.rv_edit_btn a:hover img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
#reg_label {
  font-size: 16px;
  padding: 20px;
  color: #adadad;
  text-align: center;
}
/* Returned Vehicle End */
/* Our Benifits Start*/
.our_benifits_section {
  padding: 120px 0;
}
.common_left_line_label p {
  color: #612881;
  font-size: 24px;
  font-family: 'Passion One', cursive;
  position: relative;
  padding-left: 77px;
}
.common_left_line_label p::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 66px;
  height: 3px;
  background: #612881;
}
.our_benifits_info {
  margin-top: 15px;
}
.our_benifits_info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -16px;
}
.our_benifits_info ul li {
  width: 50%;
  padding: 0 16px;
  margin-bottom: 30px;
}
.our_banifits_desc h3 {
  font-weight: bold;
  font-size: 26px;
  font-family: 'Passion One', cursive;
}
.our_banifits_desc p {
  color: #777777;
}
.our_benifits_content .common_btn {
  max-width: 170px;
  width: 100%;
  margin-top: 20px;
}
.our_benifits_image {
  /* height: 100%; */
  margin-top: 40px;
}
.our_benifits_image,
.ob_img_1 {
  position: relative;
}
.ob_img_1 {
  background-color: #000;
  width: 380px;
  display: inline-block;
  z-index: 1;
}
.ob_img_1::before {
  content: '';
  position: absolute;
  bottom: -90px;
  right: -72px;
  width: 331px;
  height: 270px;
  border: 1px solid #612881;
  z-index: -1;
}
.ob_img_1 img {
  display: block;
}
.ob_img_2 {
  position: absolute;
  width: 300px;
  bottom: -300px;
  right: 20px;
  z-index: 2;
}
/* Our Benifits End*/
/* Statistics On Bike Start */
.statistics_on_bike_block {
  max-width: 1112px;
  margin: 35px auto 0;
}
.statistics_on_bike_block ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -15px;
}
.statistics_on_bike_block ul li {
  width: 25%;
  padding: 0 15px;
  border-right: 1px solid rgb(97 40 129 / 20%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.statistics_on_bike_block ul li:last-child {
  border-right: 0;
}
.st_on_bike_info {
  text-align: center;
}
.st_on_bike_info h4 {
  font-size: 30px;
  font-family: 'Passion One', cursive;
  color: #612881;
  font-weight: 400;
}
.st_on_bike_info p {
  font-weight: 500;
}
.statistics_on_bike_block ul li:nth-child(1) {
  width: 20%;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
}
/* Statistics On Bike End */
/* Pricing Section Start */
.pricing_paln_section {
  margin: 120px 0;
}
.pricing_section {
  background-image: url(../src/images/height_MB.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 56px 0 280px;
  position: relative;
}
.pricing_section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 80%);
}
.pricing_plan_wrap {
  margin-top: -275px;
}
.pricing_content {
  text-align: center;
  position: relative;
}
.pricing_content .common_section_title h2 {
  color: #fff;
}
.pricing_content .common_desc {
  max-width: 488px;
  margin: 20px auto 0;
}
.pricing_content .common_desc p {
  color: #dddddd;
}
.common_price_box {
  background: #fefefe;
  -webkit-box-shadow: 4px 8px 27px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 8px 27px rgba(0, 0, 0, 0.14);
  padding: 35px 25px;
  position: relative;
  height: 100%;
}
.price_name_label p {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}
.price_amount h3 {
  font-weight: bold;
  font-size: 50px;
}
.price_amount h3 span {
  font-size: 18px;
  color: #a4aab6;
}
.price_desc {
  margin: 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #efecec;
}
.price_desc p {
  color: #777777;
}
.price_select_value ul li {
  font-weight: 500;
  color: #929aa7;
  position: relative;
  padding-left: 35px;
}
.price_select_value ul li + li {
  margin-top: 15px;
}
.price_select_value ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  background-image: url(../src/images/dots_ic.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.common_price_box .section_btn {
  padding: 35px 15px 0;
}
.common_price_box .common_btn {
  width: 100%;
}
.pricing_paln_block {
  margin-top: 35px;
}
.gold_price_box {
  background: #310c46;
  z-index: 1;
  padding-top: 20px;
}
.gold_price_box .common_btn:hover {
  color: #fff;
  border-color: #fff;
}
.gold_price_box .price_name_label p {
  color: #9d6ab9;
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 700;
}
.gold_price_box .price_amount h3 {
  color: #fff;
}
.gold_price_box .price_amount h3 span,
.gold_price_box .price_desc p {
  color: #bdc0c9;
}
.gold_price_box .price_select_value ul li {
  color: #dddddd;
}
.gold_price_box .price_desc {
  border-color: #787777;
}
.gold_price_box .price_select_value ul li::before {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
.gold_img_bg {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.2;
  z-index: -1;
}
.popular_label {
  font-weight: 500;
  color: #fff;
  padding: 6px 12px;
  background: -o-linear-gradient(310.22deg, #652986 0%, #9a5fbb 102.9%);
  background: linear-gradient(139.78deg, #652986 0%, #9a5fbb 102.9%);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 1;
}
/* Pricing Section End */
/*  ----------------------------- About Section Start ----------------------------- */
.about_section {
  position: relative;
  background-position: left;
  background-repeat: repeat;
  background-image: url('./images/aboutus_banner.png');
  background-clip: content-box;
  margin-bottom: 108px;
  padding-top: 10px;
}
.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.about_section::after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  content: '';
  height: 100%;
  position: absolute;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgb(255 0 0 / 0%)),
    color-stop(66%, #000)
  );
  background-image: -o-linear-gradient(right, rgb(255 0 0 / 0%), #000 66%);
  background-image: linear-gradient(to left, rgb(255 0 0 / 0%), #000 66%);
}
.about_section .container {
  position: relative;
  z-index: 5;
}
.about_info_section .common_heading {
  text-align: left;
  color: #fff;
  padding-bottom: 12px;
}
.about_info_section .paragraph {
  color: #ddd;
  padding-right: 42px;
}
.about_info_section .common_btn {
  margin-top: 30px;
  width: 100%;
  max-width: 170px;
  background-color: #fff;
  color: #4e1070;
  border-color: #fff;
}
.about_info_section .common_btn:hover {
  border-color: #4e1070;
  background-color: #4e1070;
  color: #fff;
}
._bike_block {
  -webkit-transform: translateX(115px);
  -ms-transform: translateX(115px);
  transform: translateX(115px);
}
.about_bike_block img {
  margin-top: 80px;
  max-width: 132%;
}
.home_page .common_btn.login_btn {
  display: none;
}
.CookieConsent {
  padding: 10px 40px;
  font-size: 12px;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}
.CookieConsent h1 {
  font-size: 21px;
  padding-bottom: 10px;
}
#rcc-decline-button,
#rcc-confirm-button {
  padding: 5px 20px !important;
  height: 40px !important;
}
#rcc-decline-button a {
  display: none;
  color: #fff;
}
#rcc-decline-button a:first-child {
  display: block;
}
.home_page .login-alreadyHaveAc {
  display: none;
}
.signup_section .login-alreadyHaveAc {
  display: none;
}
.login_section .Signup-alreadyHaveAc {
  display: none;
}
.home_page .form_wrapper,
.signup_section .form_wrapper {
  background-size: cover;
}
.cookieolicy_page {
  padding-top: 25px;
  padding-bottom: 25px;
}
#mail-no-match {
  display: none;
  color: red;
}
/* ================= Media Css =============== */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  header .container {
    max-width: 1400px;
  }
}
@media (max-width: 1600px) {
  .about_bike_block {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  .about_bike_block img {
    max-width: 115%;
  }
}
@media (max-width: 1440px) {
  .logo img {
    max-width: 260px;
  }
  .common_rl_btn {
    min-width: 150px;
  }
  .logo {
    min-width: auto;
  }
  .about_bike_block img {
    max-width: 95%;
  }
}
@media (max-width: 1360px) {
  .about_bike_block {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
}
@media (max-width: 1199px) {
  .menu_block {
    position: fixed;
    background-color: #ffff;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 100;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    opacity: 0;
    left: -100%;
    visibility: hidden;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
  }
  .open_menu .menu_block {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  .open_menu {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }
  .open_menu {
    overflow: hidden;
    height: 100%;
  }
  .menus ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    text-align: center;
  }
  .menus ul li {
    margin-right: 0;
  }
  .menus ul li + li {
    margin-top: 15px;
  }
  .social_icon_login_btn {
    margin: unset;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    margin-top: 20px;
  }
  .header_s_icon {
    margin: 25px 0 25px;
  }
  .header_s_icon {
    display: none;
  }
  .header_s_icon.mobile_menus {
    margin-right: 20px;
    display: block;
  }
  .logo {
    position: relative;
    z-index: 120;
  }
  .menu_toggle_btn {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: auto;
    position: relative;
    z-index: 120;
  }
  .header_wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .login_rigster_btn {
    margin: 0;
  }
  .home_banner_section {
    min-height: 600px;
  }
  .returned_vehicle_box {
    padding: 40px;
  }
  .common_section_title h2 {
    font-size: 44px;
  }
  .rv_edit_btn {
    top: 20px;
    right: 20px;
  }
  .returned_v_info ul li p {
    font-size: 20px;
  }
  .our_banifits_desc p,
  .st_on_bike_info p {
    font-size: 16px;
  }
  .our_banifits_desc h3 {
    font-size: 20px;
  }
  .ob_img_2 {
    bottom: -110px;
    right: 20px;
    max-width: 220px;
  }
  .ob_img_1 {
    max-width: 350px;
  }
  .ob_img_1::before {
    bottom: -50px;
    right: -40px;
    width: 270px;
    height: 200px;
  }
  .st_on_bike_info h4 {
    font-size: 25px;
  }
  .about_section {
    padding: 70px 0;
    margin-bottom: 70px;
  }
}
@media (max-width: 991px) {
  .home_banner_title h1 {
    font-size: 50px;
  }
  .our_benifits_image {
    max-width: 435px;
    margin: 0 auto 40px;
    padding-bottom: 90px;
    height: auto;
  }
  .ob_img_2 {
    right: 50px;
    height: 285px;
    bottom: -50px;
    right: 0;
    padding-bottom: 25px;
  }
  .pricing_paln_block .row > * {
    margin-bottom: 30px;
  }
  .pricing_paln_block .row > *:last-child {
    margin-bottom: 0;
  }
  .about_section {
    background-size: cover;
    padding-bottom: 10px;
  }
  .about_section::after {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgb(255 0 0 / 0%)),
      color-stop(87%, #000)
    );
    background-image: -o-linear-gradient(bottom, rgb(255 0 0 / 0%), #000 87%);
    background-image: linear-gradient(0deg, rgb(255 0 0 / 0%), #000 87%);
  }
  .about_bike_block {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    max-width: 400px;
    margin: 0 auto;
  }
  .about_bike_block img {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .home_label_text p {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .home_banner_title h1 {
    font-size: 35px;
  }
  body {
    font-size: 16px;
  }
  .home_banner_content .common_desc {
    margin: 10px auto 20px;
  }
  .home_banner_search_bar button {
    width: 165px;
    min-height: 50px;
    font-size: 16px;
  }
  .home_banner_search_bar input {
    width: calc(100% - 165px);
    min-height: 50px;
    padding: 12px 20px;
    font-size: 16px;
  }
  .home_banner_section {
    min-height: 500px;
  }
  .common_section_title h2 {
    font-size: 30px;
  }
  .returned_vehicle_box {
    padding: 25px 20px;
  }
  .our_benifits_section {
    padding: 50px 0;
  }
  .statistics_on_bike_block ul li:nth-child(1),
  .statistics_on_bike_block ul li {
    width: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .statistics_on_bike_block ul li:nth-child(2) {
    border-right: 0;
  }
  .pricing_paln_section {
    margin: 30px 0 50px;
  }
  .price_amount h3 {
    font-size: 40px;
  }
  .about_info_section .paragraph {
    padding-right: 0px;
    font-size: 16px;
  }
  .about_bike_block {
    margin-top: 30px;
  }
  .about_info_section .common_btn {
    margin-top: 20px;
    max-width: 140px;
    font-size: 20px;
    padding: 12px 15px 12px;
  }
  .about_section {
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .CookieConsent {
    padding: 10px 10px;
  }
}
@media (max-width: 575px) {
  .returned_v_info ul li p,
  .stolen_checkbox p {
    font-size: 16px;
  }
  .rv_edit_btn {
    top: 15px;
    right: 15px;
  }
  .rv_edit_btn a {
    width: 35px;
    height: 35px;
  }
  .common_btn {
    padding: 15px 15px 13px;
    font-size: 18px;
  }
  .returned_v_info ul {
    margin: 0 -15px;
  }
  .returned_v_info ul li {
    padding: 0 15px;
  }
  .common_left_line_label p::before {
    width: 50px;
    height: 2px;
  }
  .common_left_line_label p {
    padding-left: 65px;
    font-size: 20px;
  }
  .common_price_box {
    padding: 25px 20px;
  }
  .header_s_icon a + a {
    margin-left: 15px;
  }
}
@media (max-width: 450px) {
  .header_s_icon a {
    width: 18px;
  }
  .logo img {
    max-width: 180px;
  }
  .common_rl_btn {
    min-width: 130px;
    padding: 14px 15px 10px;
    font-size: 18px;
  }
  .ob_img_1 {
    max-width: 270px;
  }
  .ob_img_1::before {
    width: 200px;
  }
  .ob_img_2 {
    max-width: 180px;
  }
  .our_benifits_info ul li {
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
  }
  .our_benifits_info ul {
    margin: 0;
  }
  .returned_vehicle_box .common_btn,
  .our_benifits_content .common_btn {
    max-width: 100%;
  }
  .about_info_section .common_heading {
    font-size: 32px;
    padding-bottom: 20px;
  }
}
@media (max-width: 400px) {
  .returned_v_info ul li {
    width: 100%;
  }
  .home_banner_search_bar button {
    width: 105px;
  }
  .home_banner_search_bar input {
    width: calc(100% - 105px);
  }
}
@media (max-width: 367px) {
  .CookieConsent {
    padding: 10px;
  }
  .CookieConsent div:first-child {
    max-width: 260px;
  }
  .returned_vehicle_box {
    padding: 20px 12px;
  }
  .ob_img_1 {
    max-width: 220px;
  }
  .ob_img_1::before {
    height: 150px;
  }
  .logo img {
    max-width: 146px;
  }
  .header_s_icon.mobile_menus {
    margin-right: 15px;
  }
  .header_s_icon a + a {
    margin-left: 15px;
  }
}
@media (max-width: 850px) and (max-height: 375px) {
  .common_rl_btn {
    min-width: 120px;
    padding: 12px 15px 10px;
    font-size: 16px;
  }
  .header_s_icon {
    margin: 15px 0 15px;
  }
  .menus ul li a {
    font-size: 16px;
  }
  .menus ul li + li {
    margin-top: 10px;
  }
  .menu_block {
    padding-top: 70px;
  }
  .logo img {
    max-width: 220px;
  }
}
