/* Card CSS Start */
.user_card_wrapper {
    margin-bottom: 110px;
}
.common_heading {
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 30px;
    line-height: 1;
    font-family: 'Passion One', cursive;
}
.user_card_block:nth-child(3) .user_card_info p {
    max-width: 195px;
}
.user_card_block:hover {
    cursor: pointer;
}
.user_card_block:hover .user_card_image::after {
    opacity: 1;
}
.user_card_block:hover .search_img {
    opacity: 1;
}
.user_card_info h3 {
    font-weight: 700;
    font-size: 22px;
    font-family: 'Passion One', cursive;
}
.user_card_info p {
    max-width: 225px;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 16px;
}
.user_card_info {
    text-align: center;
    color: #fff;
    padding: 15px;
    padding-top: 28px;
    padding-bottom: 32px;
    background-image: url(../../images/user_card_bg1.png);
}
.user_card_block:nth-child(2) .user_card_info {
    background-image: url(../../images/user_card_bg2.png);
}
.user_card_block:nth-child(3) .user_card_info {
    background-image: url(../../images/user_card_bg3.png);
}
.user_card_image {
    overflow: hidden;
    position: relative;
}
.user_card_image::after {
    left: 0;
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: rgb(0 0 0 / 65%);
}
.user_card_image .search_img {
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 5;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.user_card_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.card_social_media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.card_social_media a {
    margin: 0 7px;
    width: 37px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    background-color: transparent;
}
.card_social_media a img {
    width: 17px;
    height: 17px;
}
.card_social_media a:hover {
    background-color: #612881;
}
/* Card CSS End */
/* Responsive CSS Start */
@media (max-width:1199px) {
    .user_card_block {
        width: 31%;
    }
    .user_card_info p {
        padding: 15px 0;
    }
}
@media (max-width:991px) {
    .user_card_info {
        padding-top: 20px;
        padding-bottom: 25px;
    }
    .user_card_info h3 {
        font-size: 18px;
    }
    .user_card_info p {
        font-size: 14px;
    }
    .common_heading {
        font-size: 43px;
        padding-bottom: 20px;
    }
    .card_social_media a {
        width: 32px;
        height: 32px;
    }
}
@media (max-width:767px) {
    .user_card_block+.user_card_block {
        margin-top: 30px;
    }
    .user_card_image img:last-child {
        width: 100%;
    }
    .user_card_block {
        width: 80%;
        margin: 0 auto;
    }
    .user_card_wrapper {
        margin-bottom: 60px;
    }
}
@media (max-width:450px) {
    .user_card_block {
        width: 100%;
    }
    .common_heading {
        font-size: 32px;
    }
}
/* Responsive CSS End */