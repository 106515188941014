.videoContainer {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 90%;
  height: 25vh;
}

.videoSectionContainer .common_section_title h2 {
  margin-top: 60px;
}
/* Benefits section */
.Benefits-section-container {
  max-width: 80%;
  margin: auto;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 1rem;
}
.benefitsHeader {
  text-align: center;
  font-weight: 800;
}
.benefitsDiv {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  position: relative;
}
.benefitsImage {
  display: flex;
  justify-content: center;
}
.benefitsText h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
}
.benefitsText p {
  font-size: 0.8rem;
  text-align: center;
}
.store_buttons {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}
.store_buttons_google{
  border-radius: 13px;
  width: 250px; 
  height: 83px;
  padding: 5px;
}
.store_buttons_apple{
  border-radius: 13px;
  width: 250px; 
  height: 83px;
  padding: 5px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btnDiv button {
  width: 300px;
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.2rem;
}
.div2 {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 1rem;
}
.div2 .benefitsImage2 {
  display: flex;
  justify-content: center;
}
.div2 .benefitsText2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.div2 .benefitsText2 {
  font-size: 0.8rem;
  max-width: 100%;
}
.div2 .benefitsText2 h4 {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;

  margin-bottom: 0.3rem;
  /* margin-right: 150px; */
}
.border1 {
  position: absolute;
  border-top: 2px solid rgb(97 40 129 / 20%);
  top: -5%;
  left: 9%;
  right: 9%;
}
.border2 {
  position: absolute;
  border-top: 2px solid rgb(97 40 129 / 20%);
  top: -3%;
  left: 9%;
  right: 9%;
}
/* About section */
.logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  margin-top: 20px;
}
.logos img {
  width: 70px;
  height: 70px;
}
.aboutimgDiv {
  margin: 0;
  padding: 0;
}
.aboutimgDiv img {
  height: 300px;
  width: 500px;
  margin-top: 0;
  margin-bottom: 0;
}
.aboutText .paragraph {
  font-size: 0.8rem !important;
}
.partnershipsText {
  color: white;

  margin-top: 20px;
}
/* smart water section */
.smartwaterDiv {
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: #612881;
  color: white;
  margin-top: -60px;
}

.smartwaterDiv p a {
  color: white;
  font-weight: 600;
  /* border: 1px solid white;
  border-radius: 20%; */
  padding: 2px 5px;
  /* background: #da83f7; */
  text-decoration: underline;
}
.smartwaterLogo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  display: none;
}
.smartwaterLogo img {
  width: 130px;
  height: 100px;
}

@media (min-width: 768px) {
  .videoContainer {
    height: 35vh;
  }
  /* Benefits section */
  .benefitsDiv h4 {
    font-size: 1.8rem;
  }
  .benefitsDiv p {
    font-size: 1.1rem;
  }
  .div2 .benefitsText2 h4 {
    font-size: 1.8rem;
  }
  .div2 .benefitsText2 p {
    font-size: 1.1rem;
  }
  /* smart water section */
  .smartwaterDiv {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: #612881;
    color: white;
    margin-top: -81px;
  }
  .smartwaterLogo {
    height: 200px;
  }
  .smartwaterLogo img {
    width: 300px;
    height: 200px;
  }
}
@media (min-width: 900px) {
  .videoContainer {
    width: 70%;
    height: 50vh;
  }
  .benefitsHeader h2 {
    margin-bottom: 20px;
  }
  .div2 .benefitsText2 {
    margin-bottom: 20px;
  }
  .smartwaterDiv {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: #612881;
    color: white;
    margin-top: -70px;
  }
  .smartwaterLogo img {
    width: 350px;
    height: 210px;
  }
}
@media (min-width: 1200px) {
  .videoContainer {
    width: 1200px;
    height: 50vh;
  }
  /* Benefits section */
  .Benefits-section-container {
    width: 80%;
    /* border: 1px solid black; */
    gap: 70px;
    margin-top: 50px;
  }
  .benefitsHeader h2 {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    width: 60%;
  }
  .benefitsDiv {
    flex-direction: row;
    justify-content: center;
    align-items: start;

    gap: 20px;
    padding: 20px;
    position: relative;
  }
  .benefitsDiv .benefitsImage {
    display: flex;
    justify-content: start;
    margin-left: 90px;
    flex-basis: 50%;
    padding-left: 20px;
  }
  .benefitsDiv .benefitsImage img {
    /* height: 100%; */
    max-width: auto;
    /* min-width: 300px; */

    margin-left: 30px;
  }
  .benefitsDiv .benefitsImage {
    position: contained;
  }
  .benefitsText {
    display: flex;
    flex-basis: 50%;

    flex-direction: column;
    align-items: flex-end;
    padding: 0rem;
    padding-right: 0;
  }
  .benefitsText h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
    width: 550px;
  }
  .benefitsText p {
    font-size: 1.3rem;
    max-width: 550px;
    text-align: left;
  }

  .border1 {
    position: absolute;
    border-top: 2px solid gray;
    top: -5%;
    left: 19%;
    right: 19%;
  }
  .border2 {
    position: absolute;
    border-top: 2px solid gray;
    top: -10%;
    left: 19%;
    right: 19%;
  }
  .div2 {
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: no-wrap;
    padding-top: 0;
  }

  .div2 .benefitsImage2 {
    display: flex;
    justify-content: end;
    flex-basis: 50%;
    margin-right: 90px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .div2 .benefitsImage2 img {
    /* padding-top: 40px; */
  }
  .div2 .benefitsText2 {
    flex-basis: 50%;

    display: flex;
    flex-direction: column;
    /* align-items: end; */
    text-align: left;
    margin-left: 20px;
  }
  .div2 .benefitsText2 {
    font-size: 1.2rem;
    max-width: 550px;
  }
  .div2 .benefitsText2 h4 {
    width: 550px;
    text-align: left;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    /* margin-right: 150px; */
  }
  .div2 .benefitsText2 p {
    font-size: 1.3rem;
    max-width: 550px;
    text-align: left;
  }
  .div3 {
    gap: 20px;
  }
  .btnDiv button {
    width: 500px;

    font-size: 1.9rem;
  }

  /* about section */
  .aboutText {
    margin-right: 0px;
  }
  .logos {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    margin-right: 20px;
  }
  .partnershipsText {
    color: white;
    width: 150px;
    font-weight: 600;
    font-size: 1.3rem;
    padding: 1rem 0.2rem;
  }
  .aboutimgDiv {
    padding: 0;
  }
  .aboutimgDiv img {
    margin-top: 100px;
    margin-bottom: 0;
    height: 500px;
    width: 1200px;
  }

  .logos img {
    width: 130px;
    height: 110px;
  }
  .aboutimgDiv {
    margin: 0;
    padding: 0;
  }

  .aboutText .paragraph {
    font-size: 1.2rem !important;
  }
  /* smart water section  css */
  .smartwaterDiv {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background: #612881;
    color: white;
    margin-top: -112px;
  }
  .smartwaterLogo {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    width: 450px;
    height: 380px;
  }
  .smartwaterLogo img {
    width: 220px;
    height: 210px;
  }
}
/* @media (min-width: 2200px) {
  .div2 .benefitsText h4 {
    width: 90%;
    text-align: center;
  }
} */
