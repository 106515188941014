.form_wrapper {
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(../../images/MBduo.jpeg);
  margin-bottom: 200px;
}
.form_wrapper::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(24%, transparent),
    color-stop(51%, #000)
  );
  background-image: -o-linear-gradient(right, transparent 24%, #000 51%);
  background-image: linear-gradient(to left, transparent 24%, #000 51%);
}
.form_inner {
  z-index: 5;
  position: relative;
  padding: 44px 56px;
  padding-right: 0;
  color: #fff;
  max-width: 476px;
}
.form_inner h4 {
  font-size: 38px;
  font-weight: 600;
}
.form_inner p {
  color: #cdcbd0;
}
.form_block {
  padding-top: 20px;
}
.input_block + .input_block {
  margin-top: 20px;
}
.input_block label {
  font-weight: 500;
  padding-bottom: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.input_block input {
  width: 100%;
  display: block;
  padding: 17px;
  color: #cdcbd0;
  border: 1px solid #7e7f80;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.input_block.invalid input {
  border-color: red;
}
.input_block.invalid label {
  color: red;
}
.input_block input::-webkit-input-placeholder {
  color: #cdcbd0;
}
.input_block input::-moz-placeholder {
  color: #cdcbd0;
}
.input_block input:-ms-input-placeholder {
  color: #cdcbd0;
}
.input_block input::-ms-input-placeholder {
  color: #cdcbd0;
}
.input_block input::placeholder {
  color: #cdcbd0;
}
.input_block input:focus-visible {
  outline: none;
}
.input_block .forgot_password {
  text-align: right;
  padding-top: 20px;
  display: none;
}
.input_block .forgot_password a {
  color: #9d6ab9;
}
.form_btn .common_btn {
  margin-top: 37px;
  max-width: 100%;
  width: 100%;
}
.form_btn .common_btn:hover {
  color: #fff;
  border-color: #ffff;
}
.already_have_account p {
  padding-top: 22px;
  text-align: center;
  color: #cdcbd0;
}
.already_have_account a {
  color: #9d6ab9;
}
.already_have_account a:hover {
  color: #af81c7;
}
/* Responsive CSS STart */
@media (max-width: 1199px) {
  .form_wrapper {
    background-position: 460px;
  }
}
@media (max-width: 991px) {
  .form_wrapper {
    background-position: 330px;
  }
}
@media (max-width: 767px) {
  .form_inner {
    max-width: 100%;
    padding: 40px;
  }
  .form_wrapper {
    background-size: cover;
    background-position: center;
    margin-bottom: 100px;
  }
  .form_wrapper::after {
    background-image: unset;
    background-color: rgb(0 0 0 / 80%);
  }
  .input_block .forgot_password {
    padding-top: 10px;
  }
}
@media (max-width: 450px) {
  .form_inner h4 {
    font-size: 28px;
  }
  .form_inner p {
    font-size: 16px;
  }
  .input_block input {
    font-size: 16px;
    padding: 10px 15px;
  }
  .input_block label {
    font-size: 16px;
    padding-bottom: 4px;
  }
  .input_block + .input_block {
    margin-top: 12px;
  }
  .form_inner {
    max-width: 100%;
    padding: 40px 25px;
  }
  .input_block .forgot_password a {
    font-size: 16px;
  }
}
@media (max-width: 367px) {
  .form_inner {
    padding: 30px 15px;
  }
  .form_inner p {
    font-size: 14px;
  }
}
/* Responsive CSS End */
.input_block.valid input {
  border-color: red;
}
