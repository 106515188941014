/* Footer CSS Start */
footer {
  position: relative;
  background-color: #000;
  padding-top: 80px;
  color: #fff;
}
.footer_bike {
  top: -410px;
  left: -116px;
  position: absolute;
  z-index: -1;
}
.all_cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footer_title {
  font-size: 22px;
  padding-bottom: 18px;
  font-family: 'Passion One', cursive;
}
.common_col ul li a {
  color: #bebebe;
}
.common_col ul li a:hover {
  color: #903ec0;
}
.common_col ul li + li {
  padding-top: 18px;
}
.common_col {
  width: 100%;
}
.footer_col_one {
  width: 100%;
  padding-left: 100px;
  max-width: 434px;
}
.footer_col_two.common_col {
  max-width: 300px;
}
.footer_col_three.common_col {
  max-width: 236px;
}
.footer_col_four.common_col {
  max-width: 200px;
}
.footer_col_three,
.footer_col_four {
  margin-top: 51px;
}
.footer_logo a {
  display: inline-block;
}
.footer_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}
.footer_social li + li {
  margin-left: 20px;
}
.footer_social li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 41px;
  height: 41px;
  border: 1px solid #fff;
  border-radius: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer_social li a:hover {
  background-color: #612881;
}
.footer_social li a img {
  width: 21px;
  height: 20px;
}
.copyright_block {
  padding-top: 23px;
  padding-bottom: 20px;
  text-align: center;
  margin-top: 95px;
  border-top: 1px solid #414141;
}
.copyright_block p {
  font-size: 20px;
  color: #bebebe;
  font-family: 'Roboto', sans-serif;
}
.copyright_block a {
  color: #bebebe;
}
.copyright_block a:hover {
  color: #903ec0;
}
.footer_col_three {
  margin-top: 30px;
}
/* Footer CSS End */
/* Responsice CSS Start */
@media (max-width: 1600px) {
  .footer_bike {
    top: -370px;
    width: 420px;
    left: -136px;
  }
}
@media (max-width: 1440px) {
  .footer_bike {
    top: -307px;
    width: 350px;
    left: -106px;
  }
}
@media (max-width: 1199px) {
  .footer_col_one {
    padding-left: 0;
  }
  .footer_col_two.common_col {
    max-width: 240px;
  }
  .footer_col_four.common_col {
    max-width: 100px;
  }
  .copyright_block {
    margin-top: 65px;
  }
}
@media (max-width: 991px) {
  footer {
    padding-top: 60px;
  }
  .footer_col_two.common_col,
  .footer_col_three.common_col {
    max-width: 160px;
  }
  .footer_logo {
    max-width: 230px;
  }
  .copyright_block p {
    font-size: 18px;
  }
  .common_col ul li a {
    font-size: 16px;
  }
  .common_col ul li + li {
    padding-top: 10px;
  }
  .footer_social li a {
    width: 35px;
    height: 35px;
  }
  .footer_social li a img {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 767px) {
  .all_cols {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer_col_one {
    margin-bottom: 30px;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .footer_bike {
    display: none;
  }
}
@media (max-width: 440px) {
  .footer_col_two.common_col,
  .footer_col_three.common_col {
    max-width: 120px;
  }
  .copyright_block {
    margin-top: 35px;
  }
  .copyright_block p {
    font-size: 14px;
  }
}
/* Responsice CSS End */
